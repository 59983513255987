<template>
  <div>
    <a-form
      :form="form"
      :labelCol="labelCol"
      :wrapperCol="wrapperCol">
      <a-form-item
        hidden
        label="adminId"
      >
        <a-input
          v-decorator="['adminId',{ initialValue:'0' }]"
          name="adminId"></a-input>
      </a-form-item>
      <a-form-item
        help="用户名由字符5-15位数字或者字符串组成"
        label="用户名"
      >
        <a-input
          v-decorator="[
            'username',
            {rules: [{ required: true, message: '请输入用户名' }]}
          ]"
          name="username"
          placeholder="请输入用户名"/>
      </a-form-item>
      <a-form-item
        label="昵称"
      >
        <a-input
          v-decorator="[
            'nickname',
          ]"
          name="nickname"
          placeholder="请输入昵称"/>
      </a-form-item>
      <a-form-item
        help="密码字符5-15位数字或者字符串组成"
        label="密码"
      >
        <a-input
          v-decorator="[
            'password',
            {rules: [{validator: handlePass}]}
          ]"
          name="password"
          placeholder="请输入密码"
          type="password"/>
      </a-form-item>
      <a-form-item
        label="确认密码"
      >
        <a-input
          v-decorator="[
            'confirmPassword',
            {rules: [{validator: handleConfirmPass}]}
          ]"
          name="confirmPassword"
          placeholder="请再次输入密码"
          type="password"/>
      </a-form-item>
      <a-form-item
        :required="false"
        label="角色"
      >
        <a-select
          v-decorator="[
            'roleIds',
            {rules: [{ required: true, message: '请选择角色' }]}
          ]"
          mode="multiple"
          name="roleIds"
        >
          <a-select-option v-for="item in allData" :key="item.roleId" :value="item.roleId">
            {{ item.rolename }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item
        :required="false"
        label="状态"
      >
        <a-radio-group v-decorator="['status', { initialValue: 1 }]" name="status">
          <a-radio :value="1">启用</a-radio>
          <a-radio :value="2">禁用</a-radio>
        </a-radio-group>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { allList } from '@/api/role'
import { TreeSelect } from 'ant-design-vue'
export default {
  components: {
    TreeSelect
  },
  data () {
    return {
      productType: 1,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: this.$form.createForm(this),
      allData: [],
      treeData: [],
      fetching: false,
      userlist: [],
      value: []
    }
  },
  mounted () {
    this.getAllList()
  },
  methods: {
    handlePass (rule, value, callback) {
      this.password = value
      callback()
    },
    handleConfirmPass (rule, value, callback) {
      if (this.password && this.password !== value) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('两次密码输入不一致！')
      }
      callback()
    },
    getAllList () {
      allList().then(res => {
        this.allData = res.result
        console.log(this.allData)
      })
    },
    handleChange (value) {
      console.log(value)
    },
    getType () {
      this.$nextTick(() => {
        this.productType = this.form.getFieldValue('type')
      })
    },
    sekType () {
      this.productType = 2
    }
  }
}
</script>

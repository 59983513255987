var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-form',{attrs:{"form":_vm.form,"labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-form-item',{attrs:{"hidden":"","label":"adminId"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['adminId',{ initialValue:'0' }]),expression:"['adminId',{ initialValue:'0' }]"}],attrs:{"name":"adminId"}})],1),_c('a-form-item',{attrs:{"help":"用户名由字符5-15位数字或者字符串组成","label":"用户名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'username',
          {rules: [{ required: true, message: '请输入用户名' }]}
        ]),expression:"[\n          'username',\n          {rules: [{ required: true, message: '请输入用户名' }]}\n        ]"}],attrs:{"name":"username","placeholder":"请输入用户名"}})],1),_c('a-form-item',{attrs:{"label":"昵称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'nickname' ]),expression:"[\n          'nickname',\n        ]"}],attrs:{"name":"nickname","placeholder":"请输入昵称"}})],1),_c('a-form-item',{attrs:{"help":"密码字符5-15位数字或者字符串组成","label":"密码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'password',
          {rules: [{validator: _vm.handlePass}]}
        ]),expression:"[\n          'password',\n          {rules: [{validator: handlePass}]}\n        ]"}],attrs:{"name":"password","placeholder":"请输入密码","type":"password"}})],1),_c('a-form-item',{attrs:{"label":"确认密码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'confirmPassword',
          {rules: [{validator: _vm.handleConfirmPass}]}
        ]),expression:"[\n          'confirmPassword',\n          {rules: [{validator: handleConfirmPass}]}\n        ]"}],attrs:{"name":"confirmPassword","placeholder":"请再次输入密码","type":"password"}})],1),_c('a-form-item',{attrs:{"required":false,"label":"角色"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'roleIds',
          {rules: [{ required: true, message: '请选择角色' }]}
        ]),expression:"[\n          'roleIds',\n          {rules: [{ required: true, message: '请选择角色' }]}\n        ]"}],attrs:{"mode":"multiple","name":"roleIds"}},_vm._l((_vm.allData),function(item){return _c('a-select-option',{key:item.roleId,attrs:{"value":item.roleId}},[_vm._v(" "+_vm._s(item.rolename)+" ")])}),1)],1),_c('a-form-item',{attrs:{"required":false,"label":"状态"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['status', { initialValue: 1 }]),expression:"['status', { initialValue: 1 }]"}],attrs:{"name":"status"}},[_c('a-radio',{attrs:{"value":1}},[_vm._v("启用")]),_c('a-radio',{attrs:{"value":2}},[_vm._v("禁用")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }